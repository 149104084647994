<template>
  <div class="rank_list">
    <div
      class="item"
      @click="choose_it(item, index)"
      v-for="(item, index) in rank_list"
      :key="index"
      :class="menu_id === index ? 'choose_it' : 'un_choose'"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import { rank_menu, rank_list } from "@/https/rank.js";

export default {
  name: "rankList",
  components: {},
  // props: {
  //   rank_list: {
  //     type: Array
  //   },
  // },
  data() {
    return {
      menu_id: 0,
      rank_list: [],
      menu_add: [
        { name: "最新更新榜", id: "zjgx" },
        { name: "人气完本榜", id: "rqwb" },
        { name: "VIP热门榜", id: "viprm" },
        { name: "免费热门榜", id: "mfrm" },
        // {name: "最新更新榜",id: '110'},
        // {name: "人气完本榜",id: '111'},
        // {name: "VIP热门榜",id: '112'},
        // {name: "免费热门榜",id: '113'},
        { name: "付费热门榜", id: "xlzg", isBookRoom: true },
        { name: "月票榜", id: "ypzg", isBookRoom: true },
        { name: "收藏榜", id: "sczd", isBookRoom: true },
      ],
    };
  },
  computed: {},
  async created() {},
  async mounted() {
    await this.get_menu();
    // await this.check_cu_id();
  },
  watch: {},
  methods: {
    // 当前选中菜单
    get_menu() {
      rank_menu().then((res) => {
        this.rank_list = [...res.data, ...this.menu_add];
        if (this.$route.query.title) {
          this.check_cu_id(this.rank_list);
        }
      });
    },
    choose_it(row, index) {
      // 传递当前选中榜单到父组件进行数据请求
      this.menu_id = index;
      this.$emit("choose_it", row);
    },
    // 根据首页跳转时传递的title进行选中数据获取判断
    check_cu_id(arr) {
      let title = this.$route.query.title;
      this.menu_id = 0;
      arr.forEach((el, index) => {
        if (el.name === title) {
          this.choose_it(el, index);
        }
      });
      // 每次进入跳转到顶部
      // document.documentElement.scrollTop=0
    },
  },
};
</script>
<style lang='scss' scoped>
.rank_list {
  background-color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  width: 230px;
  padding: 15px 0 15px 0;
  margin-right: 15px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}

.item {
  line-height: 42px;
  padding-left: 20px;
  border-left: 3px solid transparent;
  width: 100%;
  cursor: pointer;
}

.un_choose:hover {
  color: #409eff;
}

.choose_it {
  border-left-color: #409eff;
  color: #409eff;
}
</style>
