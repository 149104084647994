<template>
  <div class="rank_show" v-if="options.length > 0">
    <!--item in options-->

    <div v-for="(item, index) in options" :key="index" class="rank_show_item">
      <rankShowItem
        :options="item"
        :index="index + limit * (page - 1)"
        :is_show="true"
      ></rankShowItem>
    </div>
  </div>
</template>
<script>
import rankShowItem from "./rank-show-item";

export default {
  data() {
    return {};
  },
  components: {
    rankShowItem,
  },
  props: {
    options: {
      type: Array,
    },
    limit: {
      type: Number,
    },
    page: {
      type: Number,
    },
  },
  methods: {},
  created() {
    // this.options.forEach(item => {
    //     item.is_show = true;
    // })
  },
};
</script>
<style lang="scss" scoped>
.rank_show {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.rank_show_item {
  width: 100%;
  height: 200px;
  border: none;
  padding: 0 10px;
  border-bottom: 1px solid #eeeeee;
}
</style>
