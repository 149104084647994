<template>
  <div class="rank container" flex row nowrap>
    <div class="rank_left">
      <rankList @choose_it="choose_it($event)"></rankList>
    </div>
    <div class="rank_right" flex column between v-loading="loading">
      <NoneData v-if="item_arr.length === 0"></NoneData>
      <!-- <div  class="hidden_box" v-if="item_arr.length===0" flex center align>
                  <p>暂时没有数据哦~</p>
            </div> -->
      <rankShow
        :options="item_arr"
        :limit="page.limit"
        :page="page.page"
        v-if="item_arr.length > 0"
      ></rankShow>
      <c-page
        :page="page"
        :callBack="loadData"
        v-if="item_arr.length > 0"
      ></c-page>
    </div>
  </div>
</template>
<script>
import rankList from "./components/rank-list";
import rankShow from "./components/rank-show";
import { rank_menu, rank_list } from "@/https/rank.js";
import { book_roon } from "@/https/stack_room";

export default {
  name: "rank",
  components: {
    rankList,
    rankShow,
  },
  data() {
    return {
      loading: false,
      page: { page: 1, limit: 10, total: 0 },
      rank_list: [
        // { name: "综合热门榜", id: 1 },
        // { name: "热销榜", id: 2 },
        // { name: "新书潜力榜", id: 3 },
        // { name: "自定义榜单", id: 4 },
        // { name: "最新更新榜", id: 5 },
        // { name: "人气完本榜", id: 6 },
        // { name: "vip热门榜", id: 7 },
        // { name: "免费热门榜", id: 8 }
      ],
      // 存放当前选中榜单的数据
      item_arr: [],
      cu_id: 1,
      type: 1,
    };
  },
  async created() {
    // await this.get_menu();
    await this.get_data(this.cu_id);
  },
  mounted() {
    //     history.pushState(null, null, document.URL);
    //     window.addEventListener('popstate', function () {
    //     　　history.pushState(null, null, document.URL);
    // 　　 });
  },
  watch() {},
  methods: {
    // 从后台根据榜单id请求每个榜单数据
    loadData() {
      if (this.type == 1) {
        this.get_data(this.cu_id);
      } else if (this.type == 2) {
        this.getRoomData(this.cu_id);
      }
    },
    //从子组件 传递当前选中榜单
    choose_it(row) {
      this.page.page = 1;
      if (row && row.isBookRoom) {
        this.getRoomData(row.id);
        this.type = 2;
      } else {
        this.get_data(row.id);
        this.type = 1;
      }
      this.cu_id = row.id;
    },
    // 根据id获取数据
    get_data(id) {
      this.loading = true;
      let params = {
        id: id,
        page: this.page.page,
        limit: this.page.limit,
      };
      rank_list(params)
        .then((res) => {
          // console.log('右侧数据',res.data);
          this.item_arr = res.data;
          this.page.total = res.total;
          this.item_arr.forEach((el) => {
            el.is_show = true;
          });
        })
        .finally(() => (this.loading = false));
    },
    getRoomData(id) {
      this.loading = true;
      let params = {
        tag: id,
        page: this.page.page,
        limit: this.page.limit,
      };
      book_roon(params)
        .then((res) => {
          this.item_arr = res.data;
          this.page.total = res.total;
          this.item_arr.forEach((el) => {
            el.is_show = true;
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang='scss' scoped>
.rank {
  margin-top: 20px;

  .rank_left {
    // background-color: #fff;
  }

  .rank_right {
    background-color: #fff;
    width: calc(100% - 245px);
    // border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 0 10px 10px;
    box-shadow: 2px 2px 5px rgba(245, 242, 242, 0.2);

    .hidden_box {
      color: #999;
      font-size: 14px;
      width: 100%;
      height: 100%;
    }
  }
}
</style>

