import {request} from "./config"

// let NewUrl = 'http://192.168.0.128:81'
let NewUrl = '';


// 排行获取左侧菜单标签
export const rank_menu = (params) => request({
    method: 'get',
    url: NewUrl + 'web/rank_title',
    data: params
})

// 排行根据不同标签id获取数据
export const rank_list = (params) =>request({
    method:'get',
    url:NewUrl + 'web/rank_list',
    data:params
})


